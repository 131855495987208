import * as React from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';

import { YUPANA_LANDING_URL } from "@constants/defaults";
import { client } from '@utils/client';
import { Header } from '@components/common/Header';
import { Footer } from '@components/common/Footer';
import { ApolloProvider } from '@apollo/client';
// @ts-ignore
// import ogImage from '@images/og_image.jpg';

import * as s from './BaseLayout.module.sass';

const siteMetadata = {
  title: 'Yupana.Finance is an open-source Tezos-based lending protocol',
  description: 'The most advanced Lending protocol. Permissionless, non-custodial, and decentralized solution for any user. Deposit and Lend crypto easily.',
  author: 'MadFish',
  lang: 'en',
  // image: ogImage,
  url: YUPANA_LANDING_URL,
};

type BaseLayoutProps = {
  seo?: {
    title?: string
    description?: string
  }
  className?: string
};

export const BaseLayout: React.FC<BaseLayoutProps> = ({
  seo,
  className,
  children,
}) => (
  <ApolloProvider client={client}>
    <Helmet
      htmlAttributes={{ lang: siteMetadata.lang }}
      title={siteMetadata.title}
      titleTemplate={siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: seo?.description ?? siteMetadata.description,
        },
        // {
        //   name: 'image',
        //   content: siteMetadata.image,
        // },
        {
          property: 'og:title',
          content: seo?.title ?? siteMetadata.title,
        },
        {
          property: 'og:description',
          content: seo?.description ?? siteMetadata.description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        // {
        //   name: 'og:image',
        //   content: siteMetadata.image,
        // },
        {
          name: 'og:url',
          content: siteMetadata.url,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: seo?.title ?? siteMetadata.title,
        },
        {
          name: 'twitter:description',
          content: seo?.description ?? siteMetadata.description,
        },
        // {
        //   name: 'twitter:image',
        //   content: siteMetadata.image,
        // },
      ]}
    >
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-C54LVHR8DP" />
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'G-C54LVHR8DP');
      `}
      </script>
    </Helmet>

    <Header />
    <main className={cx(s.root, className)}>
      {children}
    </main>
    <Footer />
  </ApolloProvider>
);
