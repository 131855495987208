// extracted by mini-css-extract-plugin
export var root = "Header-module--root--1HeVy";
export var headerWrapper = "Header-module--headerWrapper--1pcWI";
export var container = "Header-module--container--2OlIh";
export var logotype = "Header-module--logotype--1sekc";
export var logo = "Header-module--logo--2VhGR";
export var content = "Header-module--content--1_N9w";
export var navItem = "Header-module--navItem--2JRaq";
export var mobile = "Header-module--mobile--3tLxE";
export var desktop = "Header-module--desktop--FDEmH";
export var burger = "Header-module--burger--15iLw";
export var dropdown = "Header-module--dropdown--801Pj";
export var active = "Header-module--active--etbMw";
export var navlist = "Header-module--navlist--3lf_T";