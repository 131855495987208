export const YUPANA_LANDING_URL = process.env.GATSBY_LANDING_URL ?? "https://yupana.finance/";
export const YUPANA_LENDING_APP = process.env.GATSBY_LENDING_LINK ?? "https://yupana-final-test.vercel.app";
export const YUPANA_METADATA_API = process.env.GATSBY_METADATA_API ?? "https://ithacanet-metadata.templewallet.com/";
export const YUPANA_APOLLO_CLIENT_ENDPOINT = process.env.GATSBY_APOLLO_CLIENT_ENDPOINT ?? "https://new-testnet-api.yupana.finance/v1/graphql";
export const WTEZ_CONTRACT = process.env.GATSBY_WTEZ_CONTRACT;
export const TEZOS_PRICE_API_URL = process.env.GATSBY_TEZOS_PRICE_API_URL ?? "https://api.tzkt.io/v1/quotes/last";

// Precisions
export const STANDARD_PRECISION = 18;
export const COLLATERAL_PRECISION = 54;
export const ORACLE_PRICE_PRECISION = 36;

// Logo url
export const CLOUDFLARE_IPFS = 'https://cloudflare-ipfs.com/ipfs';
export const IPFS_IO = 'https://ipfs.io/ipfs/';
export const PROXY_IMG =
  process.env.GATSBY_PROXY_IMG_API ??
  'https://img.templewallet.com/insecure/fill/50/50/ce/0/plain';
