// extracted by mini-css-extract-plugin
export var root = "Button-module--root--2y5Cu";
export var withArrow = "Button-module--withArrow--1-jbs";
export var arrow = "Button-module--arrow--1Wt76";
export var primary = "Button-module--primary--2oidx";
export var secondary = "Button-module--secondary--22Kty";
export var tertiary = "Button-module--tertiary--PyYIR";
export var light = "Button-module--light--3EB8X";
export var accent = "Button-module--accent--1VTeg";
export var clear = "Button-module--clear--2UBZc";
export var large = "Button-module--large--2mvms";
export var medium = "Button-module--medium--39itZ";
export var small = "Button-module--small--u55A3";