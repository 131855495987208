import { COOKIE_POLICY, DOCS, FAQ, PRIVACY_POLICY, TERMS_OF_USE, YUPANA_MARKETS } from './links';

export const FOOTER_LIST = [
  {
    id: 1,
    text: 'Markets',
    to: YUPANA_MARKETS,
    external: true,
  },
  {
    id: 2,
    text: 'FAQs',
    to: FAQ,
    external: true,
  },
  {
    id: 3,
    text: 'Docs',
    to: DOCS,
    external: true,
  },
  {
    id: 4,
    text: 'Terms of Use',
    to: TERMS_OF_USE,
    external: true,
  },
  {
    id: 5,
    text: 'Privacy Policy',
    to: PRIVACY_POLICY,
    external: true,
  },
  {
    id: 6,
    text: 'Cookie Policy',
    to: COOKIE_POLICY,
    external: true,
  },
];
