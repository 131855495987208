import * as React from 'react';
import { useMemo } from 'react';

import { useAssets } from "hooks/useAssets";
import { Preloader } from '@components/ui/Preloader';
import { Container } from '@components/common/Container';
import { AssetCard } from '@components/common/AssetCard';
import { assetLoading } from '@constants/content';

import * as s from './LoansScreen.module.sass';

export const LoansScreen: React.FC = () => {
  const {data, loading, error} = useAssets();

  const preparedData = useMemo(() => data ? data.sort((a, b) => +(b.borrowVolume24h.minus(a.borrowVolume24h))) : [], [data]);

  if(error) {
    return <></>
  }

  return (
    <section>
      <Container className={s.container}>
        <div className={s.blockWrapper}>
          <div className={s.block}>
            <div className={s.blockInner}>
              <h2 className={s.header}>
                Overcollateralized Loans
              </h2>
              <p className={s.description}>
                The Yupana DeFi-protocol is built to borrow in a trustless, permissionless way without third parties or intermediaries. P2P lending protocol allows borrowing funds from other users secured by cryptocurrency collateral.
              </p>
              <h3 className={s.subheader}>
                TOP 3 markets
              </h3>
              <div className={s.assets}>
              {loading ? assetLoading.map((_, idx) => (
                <Preloader 
                  key={idx} 
                  sizeT="large" 
                  theme="secondary"
                  className={s.loadingContainer} 
                />
              )) : preparedData.splice(0, 3).map(({ borrowApy, asset }: any) => (
                <AssetCard
                  key={asset.contractAddress}
                  apy={borrowApy}
                  asset={asset}
                  theme="secondary"
                  className={s.asset}
                />
              ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
