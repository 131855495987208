import React from 'react';
import Modal from 'react-modal';
import cx from  'classnames';

import * as s from './ReactModal.module.sass';

Modal.setAppElement('#___gatsby');

type ReactModalProps = {
  className?: string
} & Modal.Props;

export const ReactModal: React.FC<ReactModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cx(s.root, className)}
      overlayClassName={s.overlay}
      portalClassName={s.portal}
    >
      <div className={s.wrapper}>
        {children}
      </div>
    </Modal>
  );
};
