// extracted by mini-css-extract-plugin
export var ZeroBackground = "Background-module--ZeroBackground--1TtNK";
export var RightFirstBuilding = "Background-module--RightFirstBuilding--17OZJ";
export var LeftFirstBuilding = "Background-module--LeftFirstBuilding--TSGag";
export var RightSecondBuilding = "Background-module--RightSecondBuilding--26BSA";
export var LeftSecondBuilding = "Background-module--LeftSecondBuilding--h6U_x";
export var LeftThirdBuilding = "Background-module--LeftThirdBuilding--3H1PB";
export var RightThirdBuilding = "Background-module--RightThirdBuilding--3n7zM";
export var FinalBuilding = "Background-module--FinalBuilding--u--yx";
export var desktop = "Background-module--desktop--1x1BZ";
export var mobile = "Background-module--mobile--2qvt0";