import { AppRoutes } from 'routes/main-routes';

import { YUPANA_LENDING_APP } from './defaults';

// Mad.fish site
export const MADFISH = 'https://www.madfish.solutions/';

// GitBook
export const FAQ = 'https://yupana-finance.gitbook.io/yupana-document-portal/introduction/faq';
export const DOCS = 'https://yupana-finance.gitbook.io/yupana-document-portal/introduction/what-is-yupana.finance';
export const TERMS_OF_USE = 'https://yupana-finance.gitbook.io/yupana-document-portal/agreements/terms-of-use';
export const PRIVACY_POLICY = 'https://yupana-finance.gitbook.io/yupana-document-portal/agreements/privacy-policy';
export const COOKIE_POLICY = 'https://yupana-finance.gitbook.io/yupana-document-portal/agreements/cookie-policy';

// Social
export const TWITTER = 'https://twitter.com/YupanaFinance';
export const REDIIT = 'https://www.reddit.com/r/MadFishCommunity';
export const DISCORD = 'https://discord.com/invite/qFRZ8kVzkv';
export const GITHUB = 'https://github.com/madfish-solutions/yupana-contracts';
export const TELEGRAM = 'https://t.me/MadFishCommunity';

// Yupana routes
export const YUPANA_LENDING = `${YUPANA_LENDING_APP}${AppRoutes.LENDING}`;
export const YUPANA_MARKETS = `${YUPANA_LENDING_APP}${AppRoutes.MARKETS}`;
