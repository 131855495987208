import * as React from 'react';
import {useMemo} from 'react';

import { useAssets } from "hooks/useAssets";
import { Preloader } from '@components/ui/Preloader';
import { Container } from '@components/common/Container';
import { AssetCard } from '@components/common/AssetCard';
import { assetLoading } from '@constants/content';

import * as s from './AssetsScreen.module.sass';

export const AssetsScreen: React.FC = () => {
  const {data, loading, error} = useAssets();

  const preparedData = useMemo(() => data ? data.sort((a, b) => +(b.supplyVolume24h.minus(a.supplyVolume24h))) : [], [data]);

  if(error) {
    return <></>
  }

  return (
    <section>
      <Container className={s.container}>
        <div className={s.block}>
          <h2 className={s.header}>
            Supply assets and get interest
          </h2>
          <p className={s.description}>
            Locked in the Yupana-protocol assets provide APY, which is determined by the market demand for the selected asset. Supply assets and earn interest managed by smart contracts.
          </p>
          <h3 className={s.subheader}>
            TOP 3 markets
          </h3>
          <div className={s.assets}>
            {loading ? assetLoading.map((_, idx) => (
              <Preloader 
                key={idx} 
                sizeT="large" 
                theme="primary"
                className={s.loadingContainer} 
              />
            )) : preparedData.splice(0, 3).map(({ supplyApy, asset }: any) => (
              <AssetCard
                key={asset.contractAddress}
                apy={supplyApy}
                asset={asset}
                className={s.asset}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};
