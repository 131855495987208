import Discord from '@icons/Discord.inline.svg';
import Doc from '@icons/Doc.inline.svg';
import Github from '@icons/Github.inline.svg';
import Reddit from '@icons/Reddit.inline.svg';
import Telegram from '@icons/Telegram.inline.svg';
import Twitter from '@icons/Twitter.inline.svg';

import { DISCORD, DOCS, GITHUB, REDIIT, TELEGRAM, TWITTER } from './links';

type SociaTypes = {
  id: number
  href: string,
  Icon: React.FC<{ className?: string }>,
  external: true,
};

export const SOCIAL_LIST: SociaTypes[] = [
  {
    id: 1,
    href: TWITTER,
    Icon: Twitter,
    external: true,
  },
  {
    id: 2,
    href: TELEGRAM,
    Icon: Telegram,
    external: true,
  },
  {
    id: 3,
    href: REDIIT,
    Icon: Reddit,
    external: true,
  },
  {
    id: 4,
    href: DISCORD,
    Icon: Discord,
    external: true,
  },
  {
    id: 5,
    href: GITHUB,
    Icon: Github,
    external: true,
  },
  {
    id: 6,
    href: DOCS,
    Icon: Doc,
    external: true,
  },
];
