import * as React from 'react';
import cx from 'classnames';
import BigNumber from "bignumber.js";

import { Asset } from 'types/asset';
import { getAssetName } from '@utils/asset/getAssetName';
import {getPrettyPercent} from "@utils/amount/getPrettyPercent";
import { AssetLogo } from '@components/common/AssetLogo';

import * as s from './AssetCard.module.sass';


type AssetCardProps = {
  theme?: keyof typeof themeClass
  apy: BigNumber.Value
  asset: Asset
  className?: string
};

const themeClass = {
  primary: s.primary,
  secondary: s.secondary,
};

export const AssetCard: React.FC<AssetCardProps> = ({
  theme = 'primary',
  apy,
  asset,
  className,
}) => {
  return (
    <div className={cx(s.card, themeClass[theme], className)}>
      <AssetLogo
        theme={theme}
        logo={{ name: asset.name, thumbnail: asset.thumbnail }}
        className={cx(s.icon)}
      />
      <div className={s.content}>
      <span className={s.symbol}>
        {getAssetName(asset)}
      </span>
        <span className={s.apy}>
        APY:
          {' '}
          {getPrettyPercent(new BigNumber(apy))}
      </span>
      </div>
    </div>
  );
}
