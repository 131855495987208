import React from 'react';
import cx from 'classnames';

import * as s from './Input.module.sass';

type InputProps = {
  error?: string
  success?: boolean
  className?: string
} & React.HTMLProps<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  error,
  success,
  disabled,
  className,
  ...props
}, ref) => {
  const compoundClassNames = cx(
    { [s.disabled]: disabled },
  );

  return (
    <div className={cx(
      s.root,
      { [s.error]: !!error },
      className,
    )}>
      <input
        className={cx(s.input, compoundClassNames)}
        ref={ref}
        disabled={disabled}
        autoComplete="off"
        {...props}
      />
      <div className={s.errorContainer}>
        {error && (
          <div className={cx(s.errorText)}>{error}</div>
        )}
      </div>
    </div>
  );
});
