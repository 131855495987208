import constate from "constate";
import { useCallback, useMemo } from "react";
import useSWR from "swr";

import { useAssetsQuery } from "generated/graphql";
import { convertUnits } from "@utils/amount";
import {STANDARD_PRECISION, COLLATERAL_PRECISION, YUPANA_METADATA_API} from "@constants/defaults";

export const [AssetsProvider, useAssets] = constate(() => {
  const { data, loading, error } = useAssetsQuery();

  const assetsString = useMemo(
    () =>
      data
        ? JSON.stringify(
          data.asset.map(
            (ass) =>
              `${ass.contractAddress}${ass.isFa2 ? `_${ass.tokenId}` : "_0"}`
          )
        )
        : null,
    [data]
  );

  const fetchAssetsMetadata = useCallback(async () => {
    if (assetsString) {
      const response = await fetch(YUPANA_METADATA_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: assetsString,
      });
      return await response.json();
    }

    return undefined;
  }, [assetsString]);

  const { data: allAssetsMetadata, error: allAssetsMetadataError } = useSWR(
    ["all-assets-metadata", assetsString],
    fetchAssetsMetadata
  );

  if (!data || !allAssetsMetadata) {
    return {
      data: null,
      loading: loading || (!allAssetsMetadata && !allAssetsMetadataError),
      error: !!error || !!allAssetsMetadataError,
    };
  }

  const totalSupplyAmount = convertUnits(
    data.assetAggregate.aggregate?.sum?.usdSupply ?? "0",
    COLLATERAL_PRECISION
  );

  const totalBorrowAmount = convertUnits(
    data.assetAggregate.aggregate?.sum?.usdBorrow ?? "0",
    COLLATERAL_PRECISION
  );

  const finalData = data.asset.map(({ contractAddress, rates, tokenId, usdSupply, usdBorrow, name, symbol, decimals, thumbnailUri }: any, i: number) => {
    const asset = {
      tokenId,
      contractAddress,
      name: allAssetsMetadata[i] ? allAssetsMetadata[i].name : name,
      symbol: allAssetsMetadata[i] ? allAssetsMetadata[i].symbol : symbol,
      decimals: allAssetsMetadata[i] ? allAssetsMetadata[i].decimals : decimals,
      thumbnail: allAssetsMetadata[i] ? allAssetsMetadata[i].thumbnailUri : thumbnailUri,
    };
    const supplyApy = convertUnits(
          rates[0]["supply_apy"],
          STANDARD_PRECISION,
        ).multipliedBy(1e2);
    const borrowApy = convertUnits(
          rates[0]["borrow_apy"],
          STANDARD_PRECISION,
        ).multipliedBy(1e2);

    const supplyVolume24h = convertUnits(
      usdSupply,
      COLLATERAL_PRECISION
    )
      .div(totalSupplyAmount)
      .multipliedBy(1e2);

    const borrowVolume24h = convertUnits(
      usdBorrow,
      COLLATERAL_PRECISION
    )
      .div(totalBorrowAmount)
      .multipliedBy(1e2);

    return {
      supplyApy,
      borrowApy,
      supplyVolume24h,
      borrowVolume24h,
      asset,
    };
  })

  return {data: finalData, loading: false, error: false};
});
