import { DOCS, FAQ } from './links';

type NavBarListProps = {
  id: number
  item: string
  href: string
  external?: boolean
};

export const NAVBAR_LIST: NavBarListProps[] = [
  {
    id: 1,
    item: 'Faq',
    href: FAQ,
    external: true,
  },
  {
    id: 2,
    item: 'Docs',
    href: DOCS,
    external: true,
  },
];
