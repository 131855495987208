import React, { FC, HTMLProps } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import Arrow from '@icons/Arrow.inline.svg';

import * as s from './Button.module.sass';

export type HTMLButtonType =
  | HTMLProps<HTMLButtonElement>
  | HTMLProps<HTMLAnchorElement>;

export type ButtonProps = {
  theme?: keyof typeof themeClass;
  sizeT?: keyof typeof sizeClass;
  withArrow?: boolean;
  external?: boolean;
  className?: string;
  activeClassName?: string;
} & HTMLButtonType;

const themeClass = {
  primary: s.primary,
  secondary: s.secondary,
  tertiary: s.tertiary,
  light: s.light,
  accent: s.accent,
  clear: s.clear,
};

const sizeClass = {
  large: s.large,
  medium: s.medium,
  small: s.small,
};

export const Button: FC<ButtonProps> = ({
  theme = 'primary',
  sizeT = 'large',
  type = 'button',
  withArrow = false,
  href,
  external = false,
  className,
  activeClassName,
  children,
  ...props
}) => {
  const classNames = cx(
    s.root,
    themeClass[theme],
    sizeClass[sizeT],
    { [s.withArrow]: withArrow && (theme === 'light' || theme === 'accent') },
    className,
  );

  const inner = (
    <>
      {children}
      {withArrow && (theme === 'light' || theme === 'accent') && (
        <Arrow className={s.arrow} />
      )}
    </>
  );

  if (href) {
    if (external) {
      return (
        <a
          href={href}
          target="_blank"
          rel="noreferrer noopener"
          className={classNames}
          {...(props as HTMLProps<HTMLAnchorElement>)}
        >
          {inner}
        </a>
      );
    }
    return (
      <Link
        {...(props as any)}
        to={href}
        activeClassName={cx(classNames, activeClassName)}
      >
        {inner}
      </Link>
    );
  }

  return (
    <button
      // @ts-ignore
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames}
      {...props}
    >
      {inner}
    </button>
  );
};
