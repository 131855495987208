import React from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';

import { ReactModal as Modal } from '@components/ui/ReactModal';
import { Button } from '@components/ui/Button';

import * as s from './ChangeEmailModal.module.sass';

type ChangeEmailModalProps = {
  email: string
  confirm: () => void
  className?: string
} & ReactModal.Props;

export const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({
  email,
  confirm,
  isOpen,
  onRequestClose,
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cx(className)}
    >
      <div className={s.content}>
        You already receive newsletters on this email:
        <span className={s.email}>
          {email}
        </span>
        Do you want to change its?
      </div>

      <div className={s.buttonsWrapper}>
        <Button
          sizeT="small"
          onClick={onRequestClose}
          className={s.button}
        >
          No
        </Button>
        <Button
          sizeT="small"
          onClick={confirm}
          className={s.button}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};
