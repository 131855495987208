import * as React from 'react';
import cx from 'classnames';

import { Container } from '@components/common/Container';
import ZeroBackground from '@images/ZeroBackground.svg';
import RightFirstBuilding from '@images/RightFirstBuilding.svg';
import LeftFirstBuilding from '@images/LeftFirstBuilding.svg';
import LeftSecondBuilding from '@images/LeftSecondBuilding.svg';
import RightSecondBuilding from '@images/RightSecondBuilding.svg';
import LeftThirdBuilding from '@images/LeftThirdBuilding.svg';
import RightThirdBuilding from '@images/RightThirdBuilding.svg';
import FinalBuilding from '@images/FinalBuilding.svg';

import * as s from './Background.module.sass';

export const Background: React.FC = () => (
  <>
    <Container className={cx(s.mobile)} />
    <Container className={cx(s.desktop)}>
      <img src={ZeroBackground} alt="Background" className={s.ZeroBackground} />
      <img src={RightFirstBuilding} alt="Builing One" className={s.RightFirstBuilding} />
      <img src={LeftFirstBuilding} alt="Builing Two" className={s.LeftFirstBuilding} />
      <img src={LeftSecondBuilding} alt="Builing Three" className={s.LeftSecondBuilding} />
      <img src={RightSecondBuilding} alt="Builing Four" className={s.RightSecondBuilding} />
      <img src={LeftThirdBuilding} alt="Builing Three" className={s.LeftThirdBuilding} />
      <img src={RightThirdBuilding} alt="Builing Four" className={s.RightThirdBuilding} />
      <img src={FinalBuilding} alt="Builing Four" className={s.FinalBuilding} />
    </Container>
  </>
);
