import * as React from 'react';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
// @ts-ignore
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { emailValidationFactory } from '@utils/validation/emailValidationFactory';
import { getAdvancedErrorMessage } from '@utils/validation/getAdvancedErrorMessage';
import { validateEmail } from '@utils/isEmail';
import { Button } from '@components/ui/Button';
import { Input } from '@components/ui/Input';
import { ChangeEmailModal } from '@components/home/ChangeEmailModal';
import { Container } from '@components/common/Container';
import { SocialLinks } from '@components/common/SocialLinks';

import * as s from './SubscriptionForm.module.sass';

type FormValues = {
  email: string
};

export const SubscriptionForm: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState('');
  const [alreadySubscriptions, setAlreadySubscriptions] = useState<string>('');
  const inputRef = useRef(null);

  const { handleSubmit, control, formState, setError, watch, setValue } =
    useForm<FormValues>({
      defaultValues: {
        email: '',
      },
      mode: 'onChange',
    });

  const { errors } = formState;

  const email = watch('email');

  const subscriptionsEmail = useMemo(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('subscription') as string;
    }
    return "";
  }, []);
  useEffect(() => {
    setAlreadySubscriptions(subscriptionsEmail);
  }, [subscriptionsEmail]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmitMailchimp = useCallback(async (
    email: string,
  ) => {
    const data = await addToMailchimp(email);
    if (data.result === 'error') {
      setError('email', {
        type: 'manual',
        message: 'Something went wrong, please try again',
      });
    } else {
      if (typeof window !== 'undefined') {
        localStorage.setItem('subscription', email);
      }
      setValue('email', '');
      setAlreadySubscriptions(email);
      setSubscriptionSuccess(data.msg);
      if (inputRef && inputRef.current) {
        // @ts-ignore
        inputRef.current.blur();
      }
      setTimeout(() => {
        setSubscriptionSuccess('');
      }, 5000);
    }
  }, [setError, setValue]);

  const handleConfirmModal = useCallback(async () => {
    setModalIsOpen(false);
    await handleSubmitMailchimp(email);
  }, [email, handleSubmitMailchimp]);

  const emailErrorMessage = useMemo(
    () => getAdvancedErrorMessage(errors.email),
    [errors.email],
  );
  const validateEmailErrors = useMemo(
    () => emailValidationFactory(),
    [],
  );

  const handleErrorsOnSubmit = (email: string) => {
    if (validateEmail(email)) {
      setError('email', {
        message: 'Email is not valid',
      });
      return true;
    }
    if (alreadySubscriptions === email) {
      setError('email', {
        message: 'This email is already subscribed',
      });
      return true;
    }
    return false;
  };

  const onSubmit = useCallback(async ({ email }: FormValues) => {
    if (!handleErrorsOnSubmit(email)) {
      if (alreadySubscriptions && email !== alreadySubscriptions) {
        return setModalIsOpen(true);
      }
      if (!errors.email && email) {
        await handleSubmitMailchimp(email);
      }
    }
  }, [alreadySubscriptions, errors.email, handleErrorsOnSubmit, handleSubmitMailchimp]);

  return (
    <section>
      <Container className={s.container}>
        <div className={s.content}>
          <h2 className={s.header}>
            Subscribe to our Newsletter
          </h2>
          <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FormValues>)}>
            <Controller
              name="email"
              control={control}
              rules={{ validate: validateEmailErrors }}
              render={({ field }) => (
                // @ts-ignore
                <Input
                  placeholder={subscriptionSuccess || 'Enter your email'}
                  error={emailErrorMessage}
                  success={!!subscriptionSuccess}
                  className={s.input}
                  {...field}
                  ref={inputRef}
                />
              )}
            />
            <Button
              type="submit"
              disabled={!!emailErrorMessage}
            >
              Subscribe
            </Button>
          </form>
        </div>
        <SocialLinks theme='vertical' />
      </Container>
      <ChangeEmailModal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        confirm={handleConfirmModal}
        email={alreadySubscriptions}
      />
    </section>
  );
};
