import * as React from "react";
import BigNumber from 'bignumber.js';

BigNumber.set({ EXPONENTIAL_AT: 72, DECIMAL_PLACES: 72 });

type Value = string | number | BigNumber; 

export const getPrettyPrice = ({
  value,
  dec,
  currency = '$',
  shortize = true,
  isFiat = false,
}: {
  value: BigNumber | number | string, 
  dec?: number, 
  currency?: string,
  shortize?: boolean,
  isFiat?: boolean,
}) => {
  const getPirceWithCurrency = (res: Value) => {
    if (currency === '$') {
      return `${currency} ${res}`;
    }
    return `${res} ${currency}`;
  };
  const getNumberFormat = (compactValue: Value, notation?: 'compact' | undefined) => (
    new Intl.NumberFormat(
      'en',
      {
        minimumFractionDigits: isFiat ? 2 : undefined,
        maximumFractionDigits: dec ?? 6,
        notation,
      },
    ).format(+compactValue)
  );
  const getResult = (res: Value) => (
    currency ? getPirceWithCurrency(res) : res.toString()
  );

  if (new BigNumber(value).gte(100 * 10e12)) {
    const numInSciNot: { coefficient?: number; exponent?: number } = {};
    [numInSciNot.coefficient, numInSciNot.exponent] = new BigNumber(value)
      .toExponential()
      .split("e")
      .map((item) => Number(item));
    const val = new BigNumber(numInSciNot.coefficient)
      .decimalPlaces(4, BigNumber.ROUND_DOWN)
      .toString();
    const finalVal = (
      <>{val.toString()}•10<sup>{numInSciNot.exponent}</sup></>
  );
    if (currency === '$') {
      return <>{currency} {finalVal}</>;
    }
      return <>{finalVal} {currency}</>;
  }

  if (value.toString().split('.')[0].length > 6 && shortize) {
    return getResult(getNumberFormat(value, 'compact'));
  }

  return getResult(getNumberFormat(value));
};
