import React from 'react';
import cx from 'classnames';

import { FOOTER_LIST } from 'constants/footer-list';
import { Button } from '@components/ui/Button';
import { Container } from '@components/common/Container';
import { SocialLinks } from '@components/common/SocialLinks';

import * as s from './Footer.module.sass';

type FooterProps = {
  className?: string
};

export const Footer: React.FC<FooterProps> = ({
  className,
}) => {
  return (
    <footer className={cx(s.root, className)}>
      <Container className={s.container}>
        <div>
          <SocialLinks />
          <div className={s.copyright}>
            Copyright ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            Yupana.finance
          </div>
        </div>
        <div className={s.links}>
          <div className={s.column}>
            {FOOTER_LIST.slice(0, 3).map(
              ({ id, text, to, external }) => (
                <Button
                  key={id}
                  href={to}
                  external={external}
                  theme="clear"
                  className={s.link}
                >
                  {text}
                </Button>
              ))}
          </div>
          <div className={s.column}>
            {FOOTER_LIST.slice(3, 6).map(
              ({ id, text, to, external }) => (
                <Button
                  key={id}
                  href={to}
                  external={external}
                  theme="clear"
                  className={s.link}
                >
                  {text}
              </Button>
              ))}
          </div>
        </div>
      </Container>
    </footer>
  );
};
