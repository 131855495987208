// extracted by mini-css-extract-plugin
export var container = "LoansScreen-module--container--1fzBe";
export var blockWrapper = "LoansScreen-module--blockWrapper--2mEVH";
export var block = "LoansScreen-module--block--P5coD";
export var blockInner = "LoansScreen-module--blockInner--nx4iC";
export var header = "LoansScreen-module--header--2fmR9";
export var description = "LoansScreen-module--description--3zKUy";
export var subheader = "LoansScreen-module--subheader--1nALH";
export var assets = "LoansScreen-module--assets--2LY-b";
export var asset = "LoansScreen-module--asset--2YDb3";
export var loadingContainer = "LoansScreen-module--loadingContainer--3jtyV";