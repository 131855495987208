import {
  ApolloClient, from, HttpLink, InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {YUPANA_APOLLO_CLIENT_ENDPOINT} from "@constants/defaults";
  
const httpLink = new HttpLink({
  uri: YUPANA_APOLLO_CLIENT_ENDPOINT,
});
  
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
  }
  
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache();
  
export const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache,
});
  