import React from 'react';
import cx from 'classnames';

import { NAVBAR_LIST } from '@constants/navl-list';
import { Button } from '@components/ui/Button';

import * as s from './NavList.module.sass';

type NavListProps = {
  itemClassName?: string,
  className?: string,
};

export const NavList: React.FC<NavListProps> = ({
  itemClassName,
  className,
}) => {
  return (
    <nav className={cx(s.root, className)}>
      {
        NAVBAR_LIST.map(({
          item, href, id, external,
        }) => (
          <Button
            key={id}
            href={href}
            sizeT="small"
            theme="clear"
            external={external}
            className={cx(
              s.nav,
              itemClassName,
            )}
            activeClassName={s.active}
          >
            {item}
          </Button>
        ))
      }
    </nav>
  );
};
