import * as React from 'react';
import cx from 'classnames';

import { DOCS, MADFISH } from '@constants/links';
import { Button } from '@components/ui/Button';
import { Container } from '@components/common/Container';

import * as s from './InfoScreen.module.sass';

type InfoCardProps = {
  buttonText?: string
  href?: string
  external?: boolean
  className?: string
};

const InfoCard: React.FC<InfoCardProps> = ({
  buttonText,
  href,
  external = true,
  className,
  children,
}) => (
  <div className={cx(s.cardWrapper, className)}>
    <div className={s.card}>
      {children}
    </div>
    <Button
      href={href}
      external={external}
      theme='accent'
      sizeT='medium'
      withArrow
    >
      {buttonText ?? 'Documentation'}
    </Button>
  </div>
);

export const InfoScreen: React.FC = () => (
  <section>
    <Container className={s.container}>
      <InfoCard href={DOCS}>
        Yupana is an open-source, decentralized lending platform built to securely borrow and lend digital assets via smart contracts that provides the Tezos community with the benefits of decentralized financial applications.
      </InfoCard>
      <InfoCard 
        href={MADFISH}
        buttonText='Mad.fish site' 
        className={s.secondBlock}
      >
        <p>
          From the creators of the Temple wallet and the QuipuSwap DEX, verified by external audit.
        </p>
        <p>
          Made with 💙️ by MadFish.Solutions.
        </p>
      </InfoCard>
    </Container>
  </section>
);
