import React from 'react';

import { AssetsProvider } from 'hooks/useAssets';
import { BaseLayout } from '@layouts/BaseLayout';
import { Background } from '@components/home/Background';
import { FirstScreen } from '@components/home/FirstScreen';
import { InfoScreen } from '@components/home/InfoScreen';
import { AssetsScreen } from '@components/home/AssetsScreen';
import { LoansScreen } from '@components/home/LoansScreen';
import { SubscriptionForm } from '@components/home/SubscriptionForm';

const IndexPage = () => (
  <BaseLayout>
    <AssetsProvider>
      <Background />
      <FirstScreen />
      <InfoScreen />
      <AssetsScreen />
      <LoansScreen />
      <SubscriptionForm />
    </AssetsProvider>
  </BaseLayout>
);

export default IndexPage;
