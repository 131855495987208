// extracted by mini-css-extract-plugin
export var container = "FirstScreen-module--container--3332M";
export var info = "FirstScreen-module--info--3hITG";
export var lightSource = "FirstScreen-module--lightSource--36t5Y";
export var header = "FirstScreen-module--header--1UO3x";
export var description = "FirstScreen-module--description--FbikZ";
export var locked = "FirstScreen-module--locked--2shKU";
export var br = "FirstScreen-module--br--29-SO";
export var lockedHeader = "FirstScreen-module--lockedHeader--Xap_w";
export var lockedAmount = "FirstScreen-module--lockedAmount--3UrBs";
export var preloader = "FirstScreen-module--preloader--1e7oC";