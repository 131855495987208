import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import useSWR from "swr";
import BigNumber from "bignumber.js";

import { useTvlQuery } from 'generated/graphql';
import {COLLATERAL_PRECISION, TEZOS_PRICE_API_URL} from '@constants/defaults';
import { YUPANA_LENDING } from '@constants/links';
import { convertUnits, getPrettyPrice } from '@utils/amount';
import { Preloader } from '@components/ui/Preloader';
import { Button } from '@components/ui/Button';
import { Container } from '@components/common/Container';

import * as s from './FirstScreen.module.sass';

export const FirstScreen: React.FC = () => {
  const { data, loading } = useTvlQuery();
  const prepareValue: number =  useMemo(() => (
    +(data && data.assetAggregate.aggregate?.sum?.usdSupply) || 0
  ), [data]);

  const [tezosPrice, setTezosPrice] = useState<BigNumber>(new BigNumber(1));
  const fetchTezosPrice = useCallback(async () => {
    try {
      const response = await fetch(TEZOS_PRICE_API_URL);
      return await response.json();
    } catch (e) {
      console.log("Fetch tezos price error:", e);
    }

    return undefined;
  }, []);

  const { data: allTezosData } = useSWR(
    ["tezos-price"],
    fetchTezosPrice,
    { refreshInterval: 30000 }
  );

  useEffect(() => {
    if (allTezosData && allTezosData.usd) {
      setTezosPrice(new BigNumber(allTezosData.usd));
    }
  }, [allTezosData]);

  const totalValueLocked = useMemo(() => {
    const value = (
      convertUnits(
        prepareValue,
        COLLATERAL_PRECISION,
      )
        .multipliedBy(tezosPrice).decimalPlaces(2, BigNumber.ROUND_DOWN)
    );
    return getPrettyPrice({
      value,
      shortize: false,
      isFiat: true,
    });
  }, [prepareValue, tezosPrice]);

  return (
    <section>
      <Container className={s.container}>
        <div className={s.info}>
          <h1 className={s.header}>
            The first open-source and&nbsp;non-custodial lending&nbsp;protocol on Tezos
          </h1>
          <p className={s.description}>
            Earn interest on deposits and{" "}<br className={s.br}/>borrowed assets
          </p>
          <span className={s.lightSource} />
        </div>

        <div className={s.locked}>
          <h2 className={s.lockedHeader}>
            Total Value Locked:
          </h2>
          {loading ? (
            <Preloader theme="primary" className={s.preloader} />
          ) : (
            <p className={s.lockedAmount}>
            {totalValueLocked}
          </p>
          )}
          <Button
            href={YUPANA_LENDING}
            external
            theme='tertiary'
          >
            Go to app
          </Button>
        </div>
      </Container>
    </section>
  );
};
