import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import { Button } from '@components/ui/Button';
import { Container } from '@components/common/Container';
import { Burger } from '@components/common/Burger';
import { NavList } from '@components/common/NavList';
import Logo from '@icons/Logo.inline.svg';
import { YUPANA_LENDING } from '@constants/links';

import * as s from './Header.module.sass';

type HeaderProps = {
  className?: string
};

export const Header: React.FC<HeaderProps> = ({
  className,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  const handleSwitchDropdown = useCallback(
    () => setIsOpenDropdown(!isOpenDropdown),
    [isOpenDropdown],
  );

  return (
    <header className={cx(s.root, className)}>
      <div className={s.headerWrapper}>
        <Container className={s.container}>
        <Link 
          to="/"
          className={s.logotype}
        >
          <Logo className={s.logo} />
        </Link>

        <div className={s.content}>
          <NavList 
            itemClassName={s.navItem}
            className={cx(s.navlist, s.desktop)} 
          />

          <Button 
            href={YUPANA_LENDING}
            sizeT="small"
            theme="primary"
            external
          >
            go to app
          </Button>
        </div>

        <Burger
          opened={isOpenDropdown} 
          handleSwitchDropdown={handleSwitchDropdown} 
          className={cx(s.burger, s.mobile)}
        />
      </Container>
      </div>

      <div className={cx(s.dropdown, s.mobile, { [s.active]: isOpenDropdown })}>
        <NavList
          itemClassName={s.navItem}
          className={cx(s.navlist, s.desktop)}
        />
      </div>
    </header>
  );
};
