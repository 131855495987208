// extracted by mini-css-extract-plugin
export var preloader = "Preloader-module--preloader--_PBEt";
export var layout = "Preloader-module--layout--3mqif";
export var primary = "Preloader-module--primary--2aw73";
export var light = "Preloader-module--light--1b_yD";
export var secondary = "Preloader-module--secondary--1s7Rj";
export var tertiary = "Preloader-module--tertiary--3YxC5";
export var quaternary = "Preloader-module--quaternary--3c5aV";
export var liteBlueYellow = "Preloader-module--lite-blue-yellow--3E2Q0";
export var quinary = "Preloader-module--quinary--2RMLZ";
export var liteYellowBlue = "Preloader-module--lite-yellow-blue--2MTbU";
export var lite = "Preloader-module--lite--2_8Jr";
export var preloaderLogo = "Preloader-module--preloaderLogo--3oUZJ";
export var xsmall = "Preloader-module--xsmall--1kkrb";
export var small = "Preloader-module--small--2KIS9";
export var medium = "Preloader-module--medium--sMoaM";
export var large = "Preloader-module--large--2v44g";
export var fluent = "Preloader-module--fluent--3Qa5Q";