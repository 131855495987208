import React from 'react';
import cx from 'classnames';

import { SOCIAL_LIST } from 'constants/social';
import { Button } from '@components/ui/Button';

import * as s from './SocialLinks.module.sass';

type SocialLinksProps = {
  theme?: keyof typeof themeClass
  className?: string
};

const themeClass = {
  horizontal: s.horizontal,
  vertical: s.vertical,
};

export const SocialLinks: React.FC<SocialLinksProps> = ({
  theme = 'horizontal',
  className,
}) => {
  return (
    <div className={cx(s.root, themeClass[theme], className)}>
      {
        SOCIAL_LIST.map(({ href, id, Icon, external }) => (
          <Button
            key={id}
            href={href}
            external={external}
            theme="clear"
            sizeT="small"
            className={s.link}
          >
            <Icon className={s.icon} />
          </Button>
        ))
      }
    </div>
  );
};
